.allProducts{
  background-color: whitesmoke;
}
.allProductsInner{
  padding: 10px;
  padding-bottom: 40px;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}





@media screen and (max-width: 1200px) {
  .allProductsInner{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .allProductsInner{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .allProductsInner{
    grid-template-columns: repeat(1, 1fr);
  }
}
