.buyersInfo .subheader {
  display: none;
}
.buyersInfo h2 {
  margin: 20px 0;
}
.buyersInfo .label {
  opacity: 0.5;
  line-height: 2;
}
.buyersInfo .logoutContainer {
  margin: 30px 0 20px 0;
}
.buyersInfo .logoutContainer button {
  margin-left: 20px;
}
.buyersInfo .profileContainer {
  padding-top: 0;
  background: unset;
}
.buyersInfo .profileInner {
  min-height: unset;
}
.buyersInfo .guestButton {
  width: 60%;
  margin: 0 auto;
}
.buyersInfo .guestButtonContainer {
  display: flex;
  justify-content: center;
}
.buyersInfo .profile-login {
  margin-top: 0;
}
.buyersInfo .miniLabel {
  opacity: 0.5;
  font-size: 18px;
  margin: 40px 0 5px 20%;
}

.confirmation {
  margin: 20px 0;
}
.confirmation .table {
  background-color: #fafafa;
  border: 1px solid rgb(209, 209, 209);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 10px 0;
  border-radius: 5px;
}
.productConfirm.confirmation .total {
  width: 100%;
  margin-left: 0;
}

@media screen and (max-width: 1000px) {
}
